const SocialMediaBar = () => {
  return (
    <div className="social-media-icons desktop-icons">
      <a
        className="social-icon"
        href="https://open.spotify.com/artist/2afmhE9Yj6Cm6dulgJ3Bg6"
      >
        <i class="fab fa-spotify"></i>
      </a>
      <a className="social-icon" href="https://www.facebook.com/sixtylumens">
        <i class="fab fa-facebook"></i>
      </a>
      <a className="social-icon" href="https://www.instagram.com/sixtylumens/">
        <i class="fab fa-instagram"></i>
      </a>
      <a
        className="social-icon"
        href="https://www.youtube.com/channel/UC_w4N4MN2A_5r-h6_f6ZnNg"
      >
        <i class="fab fa-youtube"></i>
      </a>
      <a className="social-icon" href="https://twitter.com/sixtylumens">
        <i class="fab fa-twitter"></i>
      </a>
    </div>
  );
};

export default SocialMediaBar;
