import React from 'react';
import './Carousel.scss';
import "./Responsive.scss"
import VideoCard from './VideoCard';
const axios = require('axios').default;

let listOfImages = [];

class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          videos: [],
          currentImage: null,
          overlayIsOpen: false
        }
        this.scrollRef = React.createRef();
      }

    componentDidMount() {
        this.getVideos();
    }

    componentWillMount() {
      listOfImages = this.importAll(require.context('../assets/band_pics/', false, /\.(png|jpe?g|svg)$/));
    }

    importAll(r) {
      return r.keys().map(r);
    }

    getVideos = () => {
        axios.get('https://j3m0asmmke.execute-api.us-east-2.amazonaws.com/dev/getVideos')
              .then((response) => {
                  this.setState({videos: response.data.videos.reverse()})
              })
              .catch((error) => {
                console.log(error);
              });
    }

    removeExcessTitle = (title) => {
        return title.replace("Sixty Lumens - ", "");
    }

    scrollRight = () => {
        this.scrollRef.current.scrollLeft += 900;
    }

    scrollLeft = () => {
        this.scrollRef.current.scrollLeft -= 900;
    }

    getHighResThumbnails = (id) => {
        if (id === 'EHMRJ3sZKDs') {
            return 'https://drive.google.com/uc?id=1YIfMIm7ObG3Ch3Q95iP44jg1nPqps45G';
        }

        else if (id === 'tgUKe6I_kpo') {
            return 'https://drive.google.com/uc?id=1gCqME4X-rYwAas-5FrTZGta-GExXtZpO'
        }

        else {
            return 'https://drive.google.com/uc?id=1DO41TZXXjaQAeSzFJCPl3B06M9AwU2l_'
        }
    }

    setOverlayImage = (image) => {
      this.setState({currentImage: image})
      this.toggleOverlay();
    }

    toggleOverlay = () => {
      this.setState({overlayIsOpen: !this.state.overlayIsOpen})
      if (!this.state.overlayIsOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
        document.body.style.overflowX = "hidden";
      }
    }

    render = () => {
        let videoCarousel = this.state.videos?.map((video) => (
          <VideoCard
            link={"https://www.youtube.com/watch?v=" + video.id}
            thumbnail={
              video.thumbnails.maxres
                ? video.thumbnails.maxres.url
                : this.getHighResThumbnails(video.id)
            }
            showTitle={this.props.showTitle}
            title={this.removeExcessTitle(video.title)}
          ></VideoCard>
        ));

        let photoCarousel = listOfImages?.map((image) => (
          <VideoCard
            thumbnail={image.default}
            onClick={() => this.setOverlayImage(image.default)}
          ></VideoCard>
        ));

        var divStyle = {
          backgroundImage: 'url(' + this.state.currentImage + ')'
      }

        return (
          <div className="carousel-container">
            {this.state.overlayIsOpen ? (
              <div id="overlay">
                <div className="close-overlay" onClick={this.toggleOverlay}>
                  <i class="fas fa-times"></i>
                </div>
                <div
                  className="image-overlay-container"
                  id="image-overlay"
                  style={divStyle}
                ></div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="scroll-paddle" onClick={this.scrollLeft}>
              <i class="fas fa-arrow-circle-left"></i>
            </div>
            <div
              className="card-container"
              data-aos="fade-down"
              ref={this.scrollRef}
            >
              {this.props.showTitle ? videoCarousel : photoCarousel}
            </div>
            <div className="scroll-paddle right" onClick={this.scrollRight}>
              <i class="fas fa-arrow-circle-right"></i>
            </div>
          </div>
        );
    };  
}

export default Carousel;