import './VideoCard.scss';
import "./Responsive.scss"

const VideoCard = (props) => {
    return (
        <a className='card'href={props.link} onClick={props.onClick}>
            <div className='card-content'>
                <div className="background" style={{backgroundImage: `url(${props.thumbnail})` }}></div>
                { props.showTitle? <div className="card-title">{props.title}</div> : <div></div>}
            </div>
        </a>
    )
};

export default VideoCard;