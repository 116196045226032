import './Email.scss'
import React from 'react'
const axios = require('axios').default;


class Email extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailValid: true,
            isLoading: false,
            emailSubmitted: false,
            failedValidationFirstTime: false
        }
    }

    handleChange = (event) => {
        this.setState({
            email: event.target.value
        });
        if (this.state.failedValidationFirstTime) {
            if (this.validateEmail(this.state.email)) {
                this.setState({emailValid: true});
            }
            else {
                this.setState({emailValid: false});
            }
        }
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    postEmail = () => {
        this.setState({isLoading: true});
        if (this.validateEmail(this.state.email)) {
            this.setState({emailValid: true});
            let postData = {
                emailAddress: this.state.email
              };
    
            axios.post('https://k39wnfpf50.execute-api.us-east-1.amazonaws.com/dev/addToUserList', postData)
              .then((response) => {
                this.setState({isLoading: false});
                this.setState({emailSubmitted: true});
                console.log(response);
              })
              .catch((error) => {
                this.setState({isLoading: false});
                this.setState({emailSubmitted: true});
                console.log(error);
              });
        }

        else {
            this.setState({isLoading: false});
            this.setState({emailValid: false});
            this.setState({failedValidationFirstTime: true});
        }
    }

    render() {
        return (
            <div className="email-form" >
                {
                    !this.state.emailSubmitted ? 
                        <div>
                            <input type="text" className={`${this.state.emailValid ? '' : 'error'}`} placeholder="Email Address" onChange={this.handleChange} />
                            <button type="button" onClick={this.postEmail}>{this.state.isLoading ?  <i className="fas fa-circle-notch fa-spin"></i> : 'SIGN UP' }</button>
                            {!this.state.emailValid &&
                                <div className="error-text">Please enter a valid email.</div>
                            }
                        </div>
                    : <div className="main-form">Thank you for joining our mailing list!</div>
                }
            </div>
        );
    }
};

export default Email;