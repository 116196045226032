import './Video.scss';
import video from "../assets/front-vid.mp4"

const Video = () => {
    return (
        <video playsInline autoPlay muted loop poster="../assets/the-unknown.png" id="front-video">
            <source src={video} type="video/mp4" />
          Video not supported.
        </video>
    )
};

export default Video;