import "typeface-roboto";
import './App.scss';
import Video from './Video';
import Carousel from './Carousel';
import logo from "../assets/voidco-logo02-01.svg"
import shirt from "../assets/shirt-mockup-1.png"
import album from "../assets/the-unknown-album-moblie.png"
import Email from "./Email"
import Menu from "./Menu"
import SocialMediaBar from "./SocialMediaBar"
import "./Globals.scss"
import "./Responsive.scss"
import 'aos/dist/aos.css'
import Aos from 'aos'

function App() {
  Aos.init();
  return (
    <div className="App">
      <Video/>
      <header className="header-section">
        <div className="header-bar">
          <span className="header-logo flex-child">
            <img src={logo}  alt="VoidCo logo" id="voidco-logo"></img>
          </span>
          <span className="header-menu flex-child">
            <a className="menu-item" href="#merch">MERCH</a>
            <a className="menu-item" href="#videos">VIDEOS</a>
            <a className="menu-item" href="#photos">PHOTOS</a>
            <a className="menu-item" href="#socials">SOCIAL</a>
            <Menu/>
          </span>
        </div>
        <div className="call-to-action-header">
          <div>SIXTY LUMENS</div>
          <div>CHARADE - OFFICIAL MUSIC VIDEO</div>
        </div>
        <div className="call-to-action">
          <div className="album-cover"><img className="album-img" src={album} alt="shirts" data-aos="fade-down"/></div>
          <a href="https://www.youtube.com/watch?v=pQS1RgHBYcQ" target='#'><button>WATCH NOW</button></a>
          <SocialMediaBar/>
          <a className="scroll-indicator" href="#merch">
            <i className="fas fa-angle-down"></i>
          </a>
        </div>
      </header>
      <a href="https://sixty-lumens.square.site/s/shop">
        <div className="section merch-section" id="merch">
          <img src={shirt} alt="shirts" data-aos="fade-down"/>
          <h1 data-aos="fade-down" className="merch-link">SHOP MERCH NOW</h1>
        </div>
      </a>
      <div className="section video-section" id="videos">
        <h1 data-aos="fade-down">VIDEOS</h1>
        <Carousel showTitle={true}></Carousel>
        <div className="horizontal-scroll-indicator"><span className="title">scroll</span><i className="fas fa-long-arrow-alt-right"></i></div>
      </div>
      <div className="section photo-section" id="photos">
        <h1 data-aos="fade-down">PHOTOS</h1>
        <Carousel showTitle={false}></Carousel>
        <div className="horizontal-scroll-indicator"><span className="title">scroll</span><i className="fas fa-long-arrow-alt-right"></i></div>
      </div>
      <div className="section signup-section" id="socials">
        <div className="email-form">
          <h1 data-aos="fade-down">SIGN UP FOR EXCLUSIVE FIRST ACCESS TO NEW MUSIC</h1>
          <Email/>
          <SocialMediaBar/>
        </div>
        <div className="footer-section">
          © Copyright Sixty Lumens 2021. All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default App;
