import "./Menu.scss";
import React from "react";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: false,
    };
  }

  componentDidMount() {
    let specifiedElement = document.getElementById("sidenav");
    let menuButton = document.getElementById("menu-button");
    document.addEventListener("click", (event) => {
      if (this.state.menuIsOpen) {
        let isClickInside = specifiedElement
          ? specifiedElement.contains(event.target)
          : null;
        let clickedMenuButton = menuButton
          ? menuButton.contains(event.target)
          : null;

        if (!isClickInside && !clickedMenuButton) {
          //alert('not inside');
          this.toggleNav();
        }
      }
    });
  }

  toggleNav = (x) => {
    this.setState({ menuIsOpen: !this.state.menuIsOpen }, () => {
      if (this.state.menuIsOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    });
  };

  render() {
    return (
      <div className="menu">
        <div id="main">
          <div
            className={`${
              this.state.menuIsOpen ? "menu-button change" : "menu-button"
            }`}
            onClick={this.toggleNav}
            id="menu-button"
          >
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
          </div>
        </div>
        <div
          className={`${
            this.state.menuIsOpen ? "sidenav open" : "sidenav closed"
          }`}
          id="sidenav"
        >
          <a href="#merch" onClick={this.toggleNav}>
            MERCH
          </a>
          <a href="#videos" onClick={this.toggleNav}>
            VIDEOS
          </a>
          <a href="#photos" onClick={this.toggleNav}>
            PHOTOS
          </a>
          <a href="#socials" onClick={this.toggleNav}>
            SOCIAL
          </a>
          <div className="social-media-icons">
            <a className="social-icon" href="https://open.spotify.com/artist/2afmhE9Yj6Cm6dulgJ3Bg6"><i class="fab fa-spotify"></i></a>
            <a className="social-icon" href="https://www.facebook.com/sixtylumens"><i class="fab fa-facebook"></i></a>
            <a className="social-icon" href="https://www.instagram.com/sixtylumens/"><i class="fab fa-instagram"></i></a>
            <a className="social-icon" href="https://www.youtube.com/channel/UC_w4N4MN2A_5r-h6_f6ZnNg"><i class="fab fa-youtube"></i></a>
            <a className="social-icon" href="https://twitter.com/sixtylumens"><i class="fab fa-twitter"></i></a>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
